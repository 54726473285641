/* eslint-disable */
import React from 'react';
import { useEvent } from 'hooks/Hooks';
import UnityContainer from 'components/UnityContainer/UnityContainer';
import {isMobile} from 'react-device-detect';
import PhotoBooth from 'components/PhotoBooth/PhotoBooth';
import WinnerFormModal from 'components/WinnerFormModal/WinnerFormModal';
import CapnVideo from 'components/CapnVideo/CapnVideo';
import firebase from 'firebase';
import WinnerFormMidgame from 'components/WinnerFormMidgame/WinnerFormMidgame';
// import { checkItems } from 'db';


const EVENTS = [
  {
    type: 'open_photobooth',
    title: 'Opening Photobooth',
    description: 'Take a photo with Capn Crunch!',
  },
  {
    type: 'complete_hunt',
    title: 'Ahoy! You Won!!',
    description: 'Enter your email for your treasure!',

  },
  {
    type: 'open_video',
    title: 'Opening Capn Video',
    description: '',
  },
  { type: 'open_winner_modal' },
  {
    type: 'open_link',
    title: 'Opening Link',
    description: '',
  },
  {
    type: 'exit_game',
    title: 'Exiting Game',
    description: 'go to https://capncrunch.com',
  },
  {
    type: 'complete_item',
    title: 'Found and Item',
    description: '',
  },
  {
    type: 'exit_from_menu',
    title: 'Exiting from menu',
    description: '',
  },
  {type: 'loaded_menu_screen'},
  {type: 'loaded_ui_manager'},
  {type: 'start_view_Sunduk(Button)'},
  {type: 'stop_view_Sunduk(Button)'},
  {type: 'start_view_SHButton_Oops!AllBerries'},
  {type: 'stop_view_SHButton_Oops!AllBerries'},
  {type: 'start_view_SHButton_ChocolateCaramelCrunch'},
  {type: 'stop_view_SHButton_ChocolateCaramelCrunch'},
  {type: "start_view_SHButton_Cap'nCrunch"},
  {type: "stop_view_SHButton_Cap'nCrunch"},
  {type: "start_view_SHButton_PeanutButterCrunch"},
  {type: "stop_view_SHButton_PeanutButterCrunch"},
  {type: "start_view_CannonButton"},
  {type: "stop_view_CannonButton"},
  {type: "start_view_SHButton_MegaBerryButton"},
  {type: "stop_view_SHButton_MegaBerryButton"},
  {type: "start_view_Button"},
  {type: "stop_view_Button"},
  {type:"start_view_SHButton_ChocolateChurroBites"},
  {type:"stop_view_SHButton_ChocolateChurroBites"},
];

const containerStyle = { 
  backgroundColor: 'black', 
  minWidth: '100vw', 
  minHeight: '90vh', 
  height: '100vh', 
  overflowY: 'hidden', 
  overflowX: 'hidden' 
};

const OpenGrid = (props) => {
  const { accepting, backgroundImg } = props;
  
  // const acceptance = checkItems();

  const [showPhotoBooth, setShowPhotoBooth] = React.useState(false);
  const [showWinnerFormModal, setShowWinnerFormModal] = React.useState(false);
  const [showWinnerFormMidgame, setShowWinnerFormMidgame] = React.useState(false);
  const [showCapnVideo, setShowCapnVideo] = React.useState(false);
  // const [completedItems, setCompletedItems] = React.useState(0);
  const [shownWinnerModal, setShownWinnerModal] = React.useState(false);

  React.useEffect(() => {
    if (document.fullscreenEnabled) {
      dismissFullScreen();
    }
  }, [showPhotoBooth, showWinnerFormModal, showWinnerFormMidgame])


  const unityContainer = React.createRef();

  EVENTS.forEach(event => {
    useEvent(event.type,
      (e) => {
        if (event.type === 'open_photobooth') {
          setShowPhotoBooth(true);
        }
        if (event.type === 'open_video') {
          unityContainer.current.setFocus(false);
          setShowCapnVideo(true);
        }
        // if (event.type === 'loaded_ui_manager' && accepting && !shownWinnerModal && isMobile) {
        //   setTimeout(() => {
        //     window.open('/surprise', '_blank');
        //     unityContainer.current.setFocus(true);
        //     setShownWinnerModal(true);
        //   }, 15000);
        // }
        if (event.type === 'exit_game') {
          const link = e.detail.link;
          window.location.replace(link);
        }
        // if (event.type === 'open_winner_modal') {
        //   if (accepting && !shownWinnerModal) setTimeout(() => {
        //     window.open('/surprise', '_blank');
        //     unityContainer.current.setFocus(true);
        //     setShownWinnerModal(true);
        //   }, 5000);
        // }
        // if (event.type === 'complete_hunt') {
        //   unityContainer.current.setFocus(false);
        //   if (accepting && !shownWinnerModal) setTimeout(() => {
        //     setShowWinnerFormModal(true);
        //   }, 1000);
        // }
        // if (event.type === 'complete_item') {
        //   const prevCount = completedItems;
        //   setCompletedItems(prevCount + 1);
        // }
        // if (event.type === 'exit_from_menu') {
        //   if (accepting && completedItems >= 2 && !shownWinnerModal) setTimeout(() => {
        //   unityContainer.current.setFocus(false);
        //     setShowWinnerFormMidgame(true);
        //   }, 1000);
        // }
        firebase.analytics().logEvent(event.type);
      });
  }, []);

  const fullScreen = () => {
    unityContainer.current.unityInstance && unityContainer.current.unityInstance.SetFullscreen(1);
    firebase.analytics().logEvent('set_fullscreen_on')
  };

  const dismissFullScreen = () => {
    unityContainer.current.unityInstance && unityContainer.current.unityInstance.SetFullscreen(0)
  }

  const dismissWinnerModal = () => {
    setShowWinnerFormModal(false);
    setShownWinnerModal(true);
  }

  const dismissWinnerModalMidgame = () => {
    setShowWinnerFormModal(false);
    setShownWinnerModal(true);
    unityContainer.current.setFocus(true);
  }

  const dismissPhotoboothModal = () => setShowPhotoBooth(false);

  const dynamicBuilds = true;

  let directory = '.';
  if (dynamicBuilds)
  {
    directory = isMobile ? 'Mobile' : 'Desktop';
  }



  return (
  <>
    <div style={containerStyle}>
      { document.fullscreenEnabled &&
        <div className="fullscreenUnity" onClick={fullScreen} />
      }
      <UnityContainer unityUrl='.' ref={unityContainer} filename={isMobile && dynamicBuilds ? 'Mobile' : 'Build'} directory={directory} />
    </div>

    { isMobile ? 
      <CapnVideo show={showCapnVideo} closeModal={() => {
        unityContainer.current.setFocus(true);
        setShowCapnVideo(false);
      }} /> 
    :
      <>
        <PhotoBooth show={showPhotoBooth} closeModal={dismissPhotoboothModal} />
        <WinnerFormModal show={accepting && showWinnerFormModal && !showPhotoBooth && !shownWinnerModal} backgroundImg={backgroundImg} closeModal={dismissWinnerModal} />
        <WinnerFormMidgame show={accepting && showWinnerFormMidgame && !showPhotoBooth && !shownWinnerModal} backgroundImg={backgroundImg} closeModal={dismissWinnerModalMidgame} />
      </>   
    }
  </>
  );
};

export default OpenGrid;
