import React from "react";
import "./AgeGate.css";
import  Typography  from "@material-ui/core/Typography";
import moment from 'moment';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import firebase from "firebase";
import TermsModal from "components/elements/TermsModal";
import PrivacyPolicyModal from "components/elements/PrivacyPolicyModal";
const capnScroll = "url('/img/capns_scroll.png')";
const classes = {
  border: 'none',
  background: 'transparent',
  fontFamily: 'Lato, san-serif'
}

function AgeGate(props) {
  const { group5, submitButton, setAgreeToAge } = props;
  const [ isValidAge, setIsValidAge ] = React.useState(false);
  const [ showPrivacyPolicy, setShowPrivacyPolicy ] = React.useState(false);
  const [ showTerms, setShowTerms ] = React.useState(false);
  const currentMoment = moment();
  const currentMonth = currentMoment.month();
  const currentDay = currentMoment.date();

  const [month, setMonth] = React.useState("");
  const [day, setDay] = React.useState("");
  const [year, setYear] = React.useState("");

  const verifyAge = () => {
    if (isValidAge) {
      localStorage.setItem('opengrid::ageVerified', true);
      firebase.analytics().logEvent('age_verified');
      setAgreeToAge(true);
      window.scroll(0,0);
    } else {
      firebase.analytics().logEvent('invalid_age');
    }
  };

  React.useEffect(() => {
      if (year !== "" && year < 2003 || (year == 2003 && month <= currentMonth && day <= currentDay)) setIsValidAge(true); 
      else setIsValidAge(false);
  }, [month, day, year]);

  return (<>

    <form onSubmit={verifyAge} name="ageGateForm" action="ageGateForm" method="post"
      style={{
        textAlign: 'center', alignItems: 'center',
        backgroundImage: capnScroll, paddingTop: '15vh', height: '100vh', width: '100%', maxHeight: 1200, maxWidth: 1500, 
        backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize:'cover'}}>
          <img className="group-5" src={group5} />
          <Typography variant="h6" style={{ color: '#190606', padding: 10, textAlign: 'center',  margin: 'auto', fontFamily:"Lato, san-serif", fontWeight:"300"}} >
              You need to be 18+ in order to enter the Cap'N Crunch Adventure. <br/>
              Please verify your date of birth:
          </Typography>
          <div style={{ textAlign: "center" , justifyContent: "center"}} >
            <MonthPicker
              defaultValue={'MM'}
              numeric                   // to get months as numbers
              short                     // default is full name
              caps                      // default is Titlecase
              endYearGiven              // mandatory if end={} is given in YearPicker
              year={year}    // mandatory
              required={true}           // default is false
              value={month}  // mandatory
              onChange={(m) => {    // mandatory
                setMonth(m);
              }}
              id={'month'}
              name={'month'}
              classes={classes}
              optionClasses={'option classes'}
            />
            <DayPicker
              defaultValue={'DD'}
              year={year}    // mandatory
              month={month}  // mandatory
              endYearGiven              // mandatory if end={} is given in YearPicker
              required={true}           // default is false
              value={day}    // mandatory
              onChange={(d) => {      // mandatory
                setDay(d);
              }}
              id={'day'}
              name={'day'}
              classes={classes}
              optionClasses={'option classes'}
            />
              <YearPicker
                defaultValue={'YYYY'}
                reverse                     // default is ASCENDING
                required={true}             // default is false
                value={year}     // mandatory
                onChange={(y) => {       // mandatory
                  setYear(y);
                }}
                id={'year'}
                name={'year'}
                classes={classes}
                optionClasses={'option classes'}
              />
            </div>
            <Typography style={{ color: '#190606', fontSize: '0.6em', padding: 10, maxWidth: '80vw', margin: 'auto', textAlign: 'center', fontFamily:"Lato, san-serif"}} >
              You must be 18+ to enter this site, and by entering you agree to our <span onClick={() => setShowTerms(true)} style={{ textDecoration: 'underline', cursor: 'pointer'}}>terms and conditions</span> and <span onClick={() => setShowPrivacyPolicy(true)} style={{ textDecoration: 'underline', cursor: 'pointer'}}>privacy policy</span>.
            </Typography>
            {isValidAge === false ? 
              <img src={submitButton} style={{ opacity: 0.4, cursor: 'not-allowed', marginTop: 10, width: '400px', maxWidth: '80vw'   }} />
              :
              <img src={submitButton} onClick={verifyAge} style={{ cursor: 'pointer', marginTop: 10, width: '400px', maxWidth: '80vw' }} />
            }
            <TermsModal show={showTerms} closeModal={() => setShowTerms(false)}/>
            <PrivacyPolicyModal show={showPrivacyPolicy} closeModal={() => setShowPrivacyPolicy(false)}/>
        </form>

                    
  </>);
}

export default AgeGate;
