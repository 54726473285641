/* eslint-disable */
import React from 'react';
import ReactDOM from "react-dom";
import ReactPlayer from 'react-player';

const CapnVideo = ( props ) => {
  const { show, closeModal, url } = props;

  const modal =  (<>
      <div className={show ? "overlayPortal" : "hidePortal"} onClick={closeModal} />
      <div className={show ? "ageGatePortal" : "hidePortal"}>
        <button className="close-button" onClick={closeModal}>&#10005;</button>
        <ReactPlayer controls={true} playing={show} height="100vh" width="100vw"
         url='https://res.cloudinary.com/hqsibq8j6/video/upload/v1626734874/Open%20Grid/Story%20%2B%20Tutorial%20Videos/BeTheCapn_30_16x9_zpz4kg.mp4' />
      </div>
    </>)
  return ReactDOM.createPortal(
     modal, document.getElementById("modal-root")
  );
};

export default CapnVideo;
