import React from 'react';
import Microsite from 'components/Microsite/Microsite';
import OpenGridContainer from 'components/OpenGridContainer/OpenGridContainer';
import firebase from 'firebase';

const Home = () => {

  const [enterOpenGrid, setEnterOpenGrid] = React.useState(false);


  const handleEnteringTheOpenGrid = () => {
    setEnterOpenGrid(true);
    firebase.analytics().logEvent('enter_open_grid');
  }

  return (
    <div>
      { enterOpenGrid ? 
        <OpenGridContainer accepting={false} backgroundImg={"https://res.cloudinary.com/dq5lw8oy1/image/upload/v1626908925/CapnCrunch/Backgound_2x_n1qrpp.png"} /> 
      : 
        <Microsite playGame={handleEnteringTheOpenGrid} />  
      }
    </div>);
};

export default Home;
