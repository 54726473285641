import Typography from '@material-ui/core/Typography'

function PrivacyPolicy() {

    return (<>
        <Typography variant="h4">PRIVACY POLICY</Typography>
        <Typography variant="h4"><a href="https://opengrid.xyz">OPEN GRID, INC.</a></Typography>
        <Typography variant="h6">Effective Date: July 23, 2021</Typography>
        <Typography variant="body1">Open Grid, Inc. ( “Open Grid”, “we”, “us” or “our”) respect your (“you”, “your” or
“users”) privacy and are committed to protecting it by keeping your information
secure.</Typography>
        
<Typography variant="body1">This privacy policy (the “Privacy Policy”) describes how your personal information is
collected, used, and shared when you access and use our website (the “Site”). By
visiting this Site, or providing your information to us, you are accepting the terms of
this Privacy Policy and of our Terms of Use (the “Terms of Use”). This Privacy
Policy is incorporated into the Terms of Use by this reference. Capitalized terms not
otherwise defined in this Privacy Policy will have the definitions ascribed to them in
the Terms of Use.</Typography>
<Typography variant="h6">Information We Collect</Typography>
<Typography variant="body1">Open Grid is in the business of creating virtual and augmented reality platforms for
experiential online platform design and development. As part of providing this
service, we collect personal information from our users in a number of ways.
When you visit the Site, we automatically collect certain information about your
device, including information about your web browser, IP address, time zone, and
some of the cookies that are installed on your device.
</Typography>
<Typography variant="body1">The information we collect from you through our Site is voluntarily availed by you to
us when you: (i) register for an account; (ii) request for the development of an
augmented reality platform; and (iii) complete an online survey and make a payment
for services. When you make a payment, or attempt to make a payment through the
Site, we also collect certain information from you, including, without limitation, your
name, billing address, payment information (such as credit or debit card information
or information required by other payment methods), email address, and phone
number.</Typography>
<Typography variant="body1">Additionally, as you browse the Site, we collect information about the individual web
pages or products that you view, what websites or search terms referred you to the
Site, and information about how you interact with the Site. We refer to this
automatically collected information as “Device Information.”</Typography>
<Typography variant="body1">We collect Device Information using the following technologies:</Typography>
<Typography variant="body1">- “Cookies”, which are data files that are placed on your device or computer and often
include an anonymous unique identifier. For more information about cookies, and
how to disable cookies, visit http://www.allaboutcookies.org.</Typography>
<Typography variant="body1">- “Log files” which are track actions occurring on the Site, and which collect data
including your IP address, browser type, Internet service provider, referring/exit
pages, and date/time stamps.</Typography>
<Typography variant="body1">- “Web beacons,” “tags,” and “pixels” which are electronic files used to record
information about how you browse the Site.</Typography>

<Typography variant="body1">- Forms / sign ups to collect information (name, email, phone number, address, DOB).</Typography>
<Typography variant="body1">When we talk about “Personal Information” or “Information” in this Privacy Policy,
we are talking both about Device Information and other information we collect from
you, including payment information.</Typography>
<Typography variant="h6">How Do We Use Your Personal Information?</Typography>
<Typography variant="body1">We use the Information that we collect generally to facilitate your access to our
services on the Site. Additionally, we use this Information to:</Typography>
<Typography variant="body1">- Communicate with you;</Typography>
<Typography variant="body1">- To respond to your requests or questions;</Typography>
<Typography variant="body1">- Process your payments and provide you with invoices;</Typography>
<Typography variant="body1">- Share information with third-parties who perform services on our behalf such
as vendors who help us manage our online registration process or payment
processes; and</Typography>
<Typography variant="body1">- Comply with the law.</Typography>
<Typography variant="body1">We use the Device Information that we collect to help us screen for potential risk and
fraud (in particular, your IP address), and more generally to improve and optimize
our Site (for example, by generating analytics about how our users browse and
interact with the Site, and to assess the success of our advertising and marketing
campaigns).</Typography>
<Typography variant="h6">How Long Do We Keep Your Information?</Typography>
<Typography variant="body1">We keep your information for as long as necessary to fulfill the purposes outlined in
this Privacy Policy unless otherwise required by law. We will only keep your Personal
Information for as long as is necessary for the purposes set out in this Privacy Policy,
unless a longer retention period is required or permitted by law (such as tax,
accounting or other legal requirements). No purpose in this Policy will require us to
keep your Personal Information for longer than 2 years.</Typography>
<Typography variant="body1">When we have no ongoing legitimate business need to process your Personal
Information, we will either delete or anonymize it, or, if this is not possible (for
example, because your Personal Information has been stored in backup archives),
then we will securely store your Personal Information and isolate it from any further
processing until deletion is possible.</Typography>
<Typography variant="h6">Sharing Your Personal Information</Typography>
<Typography variant="body1">We share your Personal Information with third parties to help us use your Personal
Information, as described above. We also use Google Analytics to help us
understand how our users use the Site. You can read more about how Google uses
your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You
can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
Finally, we may also share your Personal Information to comply with applicable laws
and regulations, to respond to a subpoena, search warrant or other lawful request for
information we receive, or to otherwise protect our rights.</Typography>
<Typography variant="h6">Do Not Track</Typography>
<Typography variant="body1">Please note that we do not alter our Site’s data collection and use practices when we
see a Do Not Track signal from your browser.</Typography>

<Typography variant="h5">Jurisdiction-Specific Rules</Typography>
<Typography variant="h6">Users in California</Typography>
<Typography variant="h6">A. Privacy Rights</Typography>

<Typography variant="body1">California residents have comprehensive privacy rights enshrined in (i) the California
Constitution, (ii) the California Online Privacy Protection Act (CalOPPA), (iii) the
Privacy Rights for California Minors in the Digital World Act, (iv) the Shine the Light
Law, and (v) the California Consumer Privacy Act of 2018 (CCPA).</Typography>
<Typography variant="body1">The California Constitution establishes the overarching inalienable rights of the
state’s residents, which includes the right to privacy.</Typography>
<Typography variant="body1">The most recent law in effect is the CCPA, which makes significant changes to
privacy rights that are explained below.</Typography>
<Typography variant="h6">B. California Consumer Privacy Act</Typography>
<Typography variant="body1">The CCPA is the most recent Californian law on privacy to go into effect. It went into
effect on January 1, 2020. This law applies to any business that (i) sells the personal
information of 50,000 California residents annually, or (ii) that has an annual gross
revenue exceeding $25 million, or (iii) derives more than 50% of its annual revenue
from sales of the personal information of California residents.</Typography>
<Typography variant="body1">Under the CCPA, California residents have the right to: (i) receive notice of a
company’s practices surrounding the collection, use, sale and sharing of their
personal information, (ii) know what personal information has been collected about
the resident and the right to receive a copy thereof, (iii) deletion upon request, of
personal information that a company has collected and retained on a California
resident, (iv) opt-out of the collection of a California resident’s personal information
by a company that sells such information, (v) opt-in for minors, if a company has
active knowledge that the California resident is a minor or willfully disregards age,
and (vi) non-discrimination of California residents that have exercised their rights
under the CCPA.</Typography>
<Typography variant="body1">We may be subject to the CCPA. Where it is deemed that we qualify to be subject to
the CCPA, and in the event that we are, we shall endeavor to provide you with the
relevant information on the use of your Personal Information, in accordance with the
law.</Typography>
<Typography variant="body1">More information is available at these resources: https://oag.ca.gov/privacy/ccpa. If
you would still like to know more about your rights as a California resident, or if you
would like to request information about our use of your personal information in
accordance with the CCPA, you can contact us using the contact details provided
below.</Typography>
<Typography variant="h6">Users in Nevada</Typography>
<Typography variant="body1">
Although we do not “sell” “covered information” of Nevada “consumers” as those
terms are defined by Chapter 603A of the Nevada Revised Statutes, if you are a
Nevada consumer, please go to the Privacy Rights Requests Center to register an e-
mail address for us to provide you notice in the event we should do so in the future,
at which point you will have an opportunity to be verified and exercise your opt-out
rights under that law. Contact us in the same manner to update your contact e-mail
address for notices. Changing your e-mail elsewhere (e.g., informational requests,
account information, etc.) will not update your Nevada notice contact information. It
is your responsibility to keep your notice contact information current.</Typography>
<Typography variant="body1">Nevada residents have the right to submit a request that we do not sell your covered
Personal Information, which you can do by emailing us or writing to us at the contact
details below:</Typography>
<Typography variant="h6">Data Retention</Typography>
<Typography variant="body1">When you make a payment through the Site, we will maintain your Information for
our records unless and until you ask us to delete this information.</Typography>
<Typography variant="h6">Minors</Typography>
<Typography variant="body1">The Site is not intended for individuals under the age of 18. Children under the age
of 18 may not set up an account but may have a parent or guardian open an account
and help them enroll in appropriate courses. Individuals younger than the required
age for consent to use online services may not use the Services. If we learn that we
have collected personal data from a child under those ages, we will take reasonable
steps to delete it.</Typography>
<Typography variant="body1">Parents who believe that we may have collected personal data from a child under 13
can submit a request for removal using the contact details below.</Typography>
<Typography variant="h6">Third-Party Sites</Typography>
<Typography variant="body1">Our Site uses external links of or to other third-party websites, applications, and
services (“Linked Sites”) that are not owned, operated or controlled by us. These
may include third party payment processor sites or applications. All such links are
provided solely as a convenience to you and neither we nor any of our respective
affiliates are responsible for any content, materials or other information located on or
accessible from any other website or app. Links to Linked Sites do not constitute
approval, sponsorship or an endorsement by association with us of such websites or
the content, products, advertising or other materials presented on such Linked Sites
thereof.</Typography>
<Typography variant="body1">We make no warranties or representations about the accuracy, completeness, or
timeliness of any content posted on the Site or by anyone other than us. We disclaim
all responsibility for any viruses or malicious code that may appear on any Linked
Sites, any damage or loss caused or alleged to be cause by or in connection with the
use of or reliance on any content, goods or services available on any Linked Site.
If you are directed to any Linked Site or otherwise rely on any Linked Site, you do so
at your own risk and assume all resulting responsibilities and consequences and are
subject to the Terms and Conditions, Privacy Policy and all other policies applicable
to such Linked Site.</Typography>
<Typography variant="h6">Changes</Typography>
<Typography variant="body1">We may update this Privacy Policy at any time, and from time to time, in order to
reflect, for example, changes to our practices or for other operational, legal or
regulatory reasons.</Typography>

<Typography variant="h6">Contact Us</Typography>
<Typography variant="body1">For more information about our Privacy Policy and practices, if you have questions,
or if you would like to make a complaint, please contact us by e-mail at or by mail
using the details provided below:</Typography>
<Typography variant="h6">Email:</Typography>
<Typography variant="body1"><a href="mailto:hello@opengrid.xyz">hello@opengrid.xyz</a></Typography>
<Typography variant="h6">Mail to Address:</Typography>
<Typography variant="body1">55 Washington Street, #424</Typography>
        <Typography variant="body1">Brooklyn NY 11231</Typography>
        <Typography variant="h6" >Or at our website: <a href="https://opengrid.xyz">https://opengrid.xyz</a></Typography>
   </>);   
}

export default PrivacyPolicy;
