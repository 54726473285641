import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Home from 'pages/Home/Home';
import NotFound from 'pages/NotFound/NotFound';
// import Surprise from 'pages/Surprise/Surprise';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import * as serviceWorker from './serviceWorker';
import './assets/css/main.css';
import PhotoboothPage from 'pages/Photobooth/PhotoboothPage';

// LogRocket.init('mahgfv/devhmh', {
//   browser: {
//     urlSanitizer: url => {
//       let sanitizedUrl = url;

//       // redact the path following /ssn/ from the URL
//       sanitizedUrl = sanitizedUrl.replace(/\/ssn\/([^\/]*)/i, '/ssn/**redacted**');

//       // redact the value of the query parameter secret_key
//       sanitizedUrl = sanitizedUrl.replace(/secret_key=([^&]*)/, 'secret_key=**redacted**');

//       // make sure you return the sanitized URL string
//       return sanitizedUrl;
//     },
//   }, network: {
//     requestSanitizer: request => {
//       // if the url contains 'delete'
//       if (request.url.toLowerCase().indexOf('delete') !== -1) {
//         // scrub out the body
//         request.body = null;
//       }

//       // if the url contains 'ignore'
//       if (request.url.toLowerCase().indexOf('ignore') !== -1) {
//         // ignore the request response pair
//         return null;
//       }

//       // scrub header value from request
//       if (request.headers['x-auth-token']) {
//         request.headers['x-auth-token'] = '';
//       }

//       // scrub header pair from request
//       request.headers['x-secret'] = null;

//       // make sure you return the modified request
//       return request;
//     },
//     responseSanitizer: response => {
//       if (response.headers['x-secret']) {
//         // removes all response data
//         return null;
//       }

//       // scrubs response body
//       response.body = null;
//       return response;
//     },

//   }
// });
// setupLogRocketReact(LogRocket);

const app = (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={Home} />
      {/* {isMobile && <Route path='/surprise' component={Surprise} />} */}
      {isMobile && <Route path='/photobooth' component={PhotoboothPage} />}
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
// serviceWorker.register();
