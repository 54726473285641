import React from "react";
import "./Microsite.css";
import  Typography  from "@material-ui/core/Typography";
import AgeGate from "components/AgeGate/AgeGate";
import TermsModal from "components/elements/TermsModal";
import PrivacyPolicyModal from "components/elements/PrivacyPolicyModal";
import { isMobile } from "react-device-detect";


function Microsite(props) {
  let verified = localStorage.getItem('opengrid::ageVerified');
  const [ agreeToAge, setAgreeToAge ] = React.useState(verified);
  const [ showPrivacyPolicy, setShowPrivacyPolicy ] = React.useState(false);
  const [ showTerms, setShowTerms ] = React.useState(false);

  return (
    <div className="container-center-horizontal">
      {!agreeToAge ? 
        <AgeGate show={!agreeToAge} setAgreeToAge={setAgreeToAge}         
        group5="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627394254/CapnCrunch/CapnCrunchAdventureLogo_2x_u3vey8.png"
        submitButton="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627234822/CapnCrunch/submit_button_2x_cg3gmp.png"
         />
      :
        <div className="microsite screen">
          <img src="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627339133/CapnCrunch/CapNScroll_OpenGrid_2x_v4tlur.png" style={{ 
                  padding: '0px',
                  maxWidth: '1400px',
                  width: '100vw'
              }}
              alt="Cap'N Crunch Adventure Scroll"
              />                                    
              <img src="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627234633/CapnCrunch/coins_2x_lscuvc.png" 
                alt="Coins"
              style={{
                                    padding: 0,
                                    width: '20vw',
                                    maxWidth: 500,
                                    marginRight: '-80%',
                                    marginTop: -150
              }} />
              
              <Typography style={{ fontFamily: "Lato, san-serif", 
              color: "#FCD711", 
              marginTop: isMobile ? '12vh' : '-2vw' , 
              fontSize: isMobile ? '2vw' : '11pt', 
              padding:'0 2vw' }} >
                To experience the full Cap’N Crunch Adventure, start it on your desktop or laptop.
              </Typography>
              <Typography style={{ color: "#FCD711", 
              fontSize: isMobile ? '1.5vw' : '9pt', padding: isMobile ? '0 2vw' : '20px 2vw',
                fontFamily: 'Lato, san-serif' }} >
                  All major browsers are supported. Use Google Chrome or Firefox for the best experience.
              </Typography>
              
                <img src="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627338841/CapnCrunch/StartNowButton_2x_klw3y1.png" onClick={() => props.playGame()} style={{ 
                  padding: 0,
                  maxWidth: '500px',
                  width: '50vw',
                  marginBottom: 0,
            marginTop: 25,
            cursor: 'pointer'
              }}
                alt="Start Now Button" />
                <img src="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627078727/CapnCrunch/Compass_2x_s6vozq.png" 
                alt="Compass"
                style={{
                        padding: 0,
                        width: '80vw',
                        maxWidth: '1000px',
                        marginLeft: '-90%',
                        marginTop: -100,
                        zIndex: 1000
                      }} />
              <img src="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627078711/CapnCrunch/Photo_Frame_A_2x_zvnivb.png" style={{ 
                  padding: '100px 0px',
                  maxWidth: '1200px',
                  width: '100vw',
                  marginTop: '-50%',
                  zIndex: 100
              }}
              alt="Cap'N Crunch Ship"
              />

          

          <div className="legal-footer" style={{ backgroundColor: `#185995` , height: 58 }}>
            <Typography style={{ fontFamily: "Lato, san-serif", color: '#FCD711'}}><span onClick={() => setShowTerms(true)} style={{ textDecoration: 'underline', cursor: 'pointer', margin: '0 20px'}}>Terms</span><span>    -    </span><span onClick={() => setShowPrivacyPolicy(true)} style={{ textDecoration: 'underline', cursor: 'pointer', margin: '0 20px'}}>Privacy Policy</span></Typography>
          </div>
        </div>     
    }
       <TermsModal show={showTerms} closeModal={() => setShowTerms(false)}/>
        <PrivacyPolicyModal show={showPrivacyPolicy} closeModal={() => setShowPrivacyPolicy(false)}/>
    </div>
  );
}

export default Microsite;
