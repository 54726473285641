/* eslint-disable */
import ReactDOM from "react-dom";
import Terms from "./Terms";
const TermsModal = ( props ) => {
  const { show, closeModal, backgroundImg } = props;

  const modal =  (<>
      <div className={show ? "overlayPortal" : "hidePortal"} onClick={closeModal} />
      <div className={show ? "modalPortal" : "hidePortal"} style={{ flexDirection:'column', position: 'fixed', overflowY:'scroll', zIndex: 10000 }}>
      <button className="close-button" onClick={closeModal}  style={{ top: '1rem', right: '1rem' }}>&#10005;</button>   


      <Terms closeModal={closeModal}/>
      </div>
    </>)
  return ReactDOM.createPortal(
     modal, document.getElementById("modal-root")
  );
};

export default TermsModal;
