import firebase from "./firebase";

const firestore = firebase.firestore();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

// Create a new item
export function createItem(data) {
  return firestore.collection("winnersTest").add({
    ...data,
    createdAt: serverTimestamp(),
  }).then(res => 'Success!')
  .catch(error => console.log(error));
}

// Create a new item
export function checkItems(data) {
  // return firestore.collection("winners").doc("A26Wex7bPQuDADcSSFtx").get(res => console.log(res))
    
  var docRef = firestore.collection("winners").doc("A26Wex7bPQuDADcSSFtx");

  // Valid options for source are 'server', 'cache', or
  // 'default'. See https://firebase.google.com/docs/reference/js/firebase.firestore.GetOptions
  // for more information.
  var getOptions = {
      source: 'server'
  };
  
  // Get a document, forcing the SDK to fetch from the offline cache.
  docRef.get(getOptions).then((doc) => {
      // Document was found in the cache. If no cached document exists,
      // an error will be returned to the 'catch' block below.
    console.log("Server document data:", doc.data().accepting);
    const accepting = doc.data();
    return accepting.accepting;
  }).catch((error) => {
      console.log("Error getting cached document:", error);
  });

  // .catch(error => console.log(error));
}