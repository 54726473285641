import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
import { isMobile } from 'react-device-detect';
import firebase from 'firebase';
import { createItem } from "../../db.js";
import useInput from '../../hooks/useInput';
import '../WinnerFormModal/WinnerFormModal.css';

const isEmail = (value) => value.includes('@');

const WinnerForm = (props) => {
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  const [formAlert, setFormAlert] = React.useState(null);
  const [successfulSubmission, setSuccessfulSubmission] = React.useState(false);
  const { handleSubmit } = useForm();
  
  const submitHandler = () => {
    const query = createItem({ email: emailValue });

    query
      .then(() => {
        firebase.analytics().logEvent('successfully_submitted_email');
        resetEmail();
        setSuccessfulSubmission(true);
      })
      .catch((error) => {
        firebase.analytics().logEvent('unsuccessfully_submitted_email');
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  }

  const emailClasses = emailHasError ? 'form-control invalid' : 'form-control';

  return (<>
    <form onSubmit={handleSubmit(submitHandler)} style={{ textAlign: 'center', alignItems: 'center', }}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
        <Box xs={12}>
          <img src={'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627311163/CapnCrunch/OG_BFxCC_PrizeClaimForm_LogoLockup_v7srbu.png'} 
          style={{  marginTop: '0',   maxWidth: '80vw',  width: '30vh'  }}/>   
        {!successfulSubmission ?
          <Typography style={{ color: 'white', fontSize: isMobile ? '0.7em' : '1.3em', padding: isMobile ? 5 : 10, maxWidth: isMobile ? '95vw' : '85vw', margin: 'auto', textAlign: 'center', fontFamily: "Lato, san-serif" }} >
            Ahoy! You are getting a limited edition Cap’n Crunch Adventure surprise! <br />Submit your email below to claim.<br />
            Follow the instructions in the email to learn more. Seriously, we are sending you something AMAZING!
          </Typography>
          :
          <>
          <Typography style={{ color: 'white', fontSize: isMobile ? '0.8em' : '1.3em', padding: 20, maxWidth: '85vw', margin: 'auto', textAlign: 'center', fontFamily: "Lato, san-serif" }} >
            Thank you for submitting your email address! <br /> <br /> Be on the lookout for an email from the Cap'n Crunch's Adventure team about receiving your limited edition Cap'N Crunch surprise.
            </Typography>
            {isMobile && <>
              <Typography style={{ color: 'white', fontSize:'0.8em', padding: 20, maxWidth: '85vw', margin: 'auto', textAlign: 'center', fontFamily: "Lato, san-serif" }} >
                Take a photo with the Cap'N before you go!
              </Typography>
              <a href="/photobooth" target="_blank"><img
              style={{ cursor:'pointer', marginTop: 40, maxWidth: '80vw',  width: isMobile ? '300px' : '600px', margin: 'auto' }}
              src="https://res.cloudinary.com/dq5lw8oy1/image/upload/v1628140230/CapnCrunch/Photobooth_Button_2x_kte83m.png" /></a>
            </>}
          </>
        }
            </Box>
      {!successfulSubmission && <>
        <Box xs={12} />
        <Box xs={12} marginTop={2}>
          <div className={emailClasses}  style={{ textAlign: 'center', alignItems: 'center'}}>
            <input
              type='email'
              id='email'
              placeholder='Enter email here'
              value={emailValue}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              style={{ margin: 'auto' }}
            />
            {emailHasError && <p className="error-text">Please enter a valid email address.</p>}
          </div>
        </Box>
        <Box xs={12}>
          <Typography style={{ color: 'white', fontSize: isMobile ? '0.5em' : '0.7em', padding: isMobile ? 5 : 10, maxWidth: isMobile ? '95vw' :'90vw', margin: 'auto', textAlign: 'center', fontFamily:"Lato, san-serif"}} >
              Your email will not be used for any marketing purposes. Limited to 1 submission per person.
          </Typography>
          <div className='form-actions'>
            <img src={'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1627394616/CapnCrunch/winnerSubmit_2x_aiz4nn.png'} 
              onClick={emailIsValid && handleSubmit(submitHandler) }
              style={{ opacity: emailIsValid ? 1 : 0.4, cursor: emailIsValid ? 'pointer' : 'not-allowed', marginTop: 40 , maxWidth: '80vw',  width: isMobile ? '300px' : '600px', margin: 'auto' }}/>
          </div>
        </Box>
      </> }
    </form>
  </>);
};

export default WinnerForm;