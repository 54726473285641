import React, { Component } from "react";
import ScriptTag from 'react-script-tag';
import { isMobile } from 'react-device-detect';
import './UnityContainer.scss';

class UnityContainer extends Component {
    constructor(props) {
        super(props);

        const filename = this.props.filename || "Build";
        const directory = this.props.directory || ".";

        this.state = {
            progress: "0%",
            progressDisplay: "",
            loaderUrl: `${this.props.unityUrl}/${directory}/Build/${filename}.loader.js`,
            buildUrl: `${this.props.unityUrl}/${directory}/Build`,
            filename,
            directory,
            activeTouches: {}
        };

        this.canvas = React.createRef();
        this.progressBarEmpty = React.createRef();
        this.progressBarFull = React.createRef();
    }

    componentDidMount() {
        const self = this;
        window.addEventListener("loaded_ui_manager", () => {
            setTimeout(() => {
                self.refreshMobileControls();
                self.refreshQualityLevel();
            }, 0);
        });

        
        window.addEventListener("resize", () => {
            setTimeout(() => {
                self.refreshMobileControls();
                self.refreshQualityLevel();
            }, 0);
        });

        const copyTouch = ({ identifier, pageX, pageY }) => {
            return { identifier, pageX, pageY };
        };

        this.canvas.current.addEventListener("touchstart", (e) => {
            e.preventDefault();

            setTimeout(() => {
                let touches = e.changedTouches;


                for (let i = 0 ; i < touches.length; i++)
                {
                    self.state.activeTouches[touches[i].identifier] = copyTouch(touches[i]);
                }


            });
        }, { passive: false });

        this.canvas.current.addEventListener("touchend", (e) => {
            e.preventDefault();

            setTimeout(() => {
                let touches = e.changedTouches;


                for (let i = 0 ; i < touches.length; i++)
                {
                    delete self.state.activeTouches[touches[i].identifier];
                }

                if (Object.keys(self.state.activeTouches).length == 0)
                {
                    self.resetJoysticks();
                }
            });
        }, { passive: false });

        this.canvas.current.addEventListener("touchcancel", (e) => {
            e.preventDefault();

            setTimeout(() => {
                let touches = e.changedTouches;

                for (let i = 0 ; i < touches.length; i++)
                {
                    delete self.state.activeTouches[touches[i].identifier];
                }

                if (Object.keys(self.state.activeTouches).length == 0)
                {
                    self.resetJoysticks();
                }
            });
        }, { passive: false });
    }

    refreshMobileControls = () => {
        if (this.unityInstance)
        {
            if (isMobile)  this.setMobileControls();
            else this.disableMobileControls();
        }
    }

    resetJoysticks = () => {
        if (this.unityInstance && isMobile)
        {
            this.unityInstance.SendMessage("GameController", "ResetMobileControls");
        }
    }

    refreshQualityLevel = () => {
        if (this.unityInstance)
        {
            if (isMobile) this.unityInstance.SendMessage("GameController", "SetQualityLevel", 1);
            else  this.unityInstance.SendMessage("GameController", "SetQualityLevel", 3); 
        }
    }

    setMobileControls = () => {  if (this.unityInstance)  this.unityInstance.SendMessage("GameController", "SetMobileMode", "1") };
    
    disableMobileControls = () =>  { if (this.unityInstance)  this.unityInstance.SendMessage("GameController", "SetMobileMode", "0") };

    setFocus = (focus) => {
        var focusStr = focus ? "1" : "0";
        if (this.unityInstance) this.unityInstance.SendMessage("GameController", "FocusCanvas", focusStr);
        this.canvas.current.focus();
        this.canvas.current.click();
    }

    refocus = () => { if (this.unityInstance) this.unityInstance.SendMessage("GameController", "Refocus")};
        


    onLoad = () => {
        var self = this;
        console.log(self)

        const filename = this.state.filename;
        const directory = this.state.directory;

        var config = {
            dataUrl: `${self.state.buildUrl}/${filename}.data`,
            frameworkUrl: `${self.state.buildUrl}/${filename}.framework.js`,
            codeUrl:  `${self.state.buildUrl}/${filename}.wasm`,
            streamingAssetsUrl: `${self.props.unityUrl}/${directory}/StreamingAssets`,
            companyName: "Open Grid",
            productName: "opengrid_retail_v1",
            productVersion: "0.1",
        };

        window.createUnityInstance(this.canvas.current, config, (progress) => {
            self.state.progress = 100 * progress;

            // Update loading bar
            self.progressBarEmpty.current.style.display = "";
            self.progressBarFull.current.style.width = `${self.state.progress}%`;

        }).then((unityInstance) => {
            self.unityInstance = unityInstance;

            // hide loading bar
            self.progressBarEmpty.current.style.display = "none";
            
            console.log("after UnityLoader.instantiate #####", { unityInstance });
        }).catch((message) => {
            alert(message);
        });
    }

    onClick = () => {
        this.refocus();
    };

    render() {
        return (
            <div id="unityContainer" className={isMobile ? "unity-mobile" : "unity-desktop"} onClick={this.onClick}>
                <ScriptTag type="text/javascript" src={this.state.loaderUrl} onLoad={this.onLoad} />
                <ScriptTag tyupe="text/javascript" src="./hls.min.js" />
                <canvas id="unity-canvas" ref={this.canvas} />
                <div id="unity-loading-bar">
                    <div id="unity-logo" />
                    <div id="unity-progress-bar-empty" ref={this.progressBarEmpty}>
                        <div id="unity-progress-bar-full" ref={this.progressBarFull} />
                    </div>
                </div>
                <div id="unity-mobile-warning" />
            </div>
        );
    }
}

export default UnityContainer;
