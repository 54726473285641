/* eslint-disable */
import WinnerForm from 'components/WinnerForm/WinnerForm';
import { isMobile } from 'react-device-detect';
import ReactDOM from "react-dom";

const WinnerFormMidgame = ( props ) => {
  const { show, closeModal } = props;
  
  const modal =  (<>
      <div className={show ? "overlayPortal" : "hidePortal"} />
      <div className={show && isMobile ? "ageGatePortal" : show ? "modalPortal" : "hidePortal"} style={{ flexDirection:'column', backgroundColor: '#185995', maxWidth: isMobile ? '100vw' : '95vw', width: isMobile && '100vw', height: isMobile && '100vh' }}>
      {!isMobile && <button className="close-button" onClick={closeModal}>&#10005;</button>}
        <WinnerForm closeModal={closeModal} />
      </div>
    </>)
  return ReactDOM.createPortal(
     modal, document.getElementById("modal-root")
  );
};

export default WinnerFormMidgame;
