import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import NotFoudImage from 'assets/404.gif';

const NotFound = () => {
  const location = useLocation();

  const userPath = '/';

  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <section style={{ margin: 'auto 0'}}>
          <div className="container">
            <div className="columns is-vcentered is-desktop">
              <div className="column has-text-centered">
                <h1 className="title">Whoops, looks like you got lost!</h1>
                <Link className="button is-info is-normal" to={userPath}>
                  Go Back
                </Link>
              </div>
              <div className="column has-text-centered">
                <img src={NotFoudImage} alt="404 error" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default NotFound;
