import PhotoBooth from 'components/PhotoBooth/PhotoBooth';
import React from 'react'

function PhotoboothPage() {
    const [show, setShow] = React.useState(true);

    return (
        <div>
            <PhotoBooth show={show} closeModal={() => console.log('close')} />
        </div>
    )
}

export default PhotoboothPage;
