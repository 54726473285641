/* eslint-disable */
import ReactDOM from "react-dom";
import PrivacyPolicy from "./PrivacyPolicy";
const PrivacyPolicyModal = ( props ) => {
  const { show, closeModal, backgroundImg } = props;

  const modal =  (<>
      <div className={show ? "overlayPortal" : "hidePortal"} onClick={closeModal} />
      <div className={show ? "modalPortal" : "hidePortal"} style={{ flexDirection:'column', position: 'fixed', overflowY:'scroll', zIndex: 10000  }}>
      <button className="close-button" onClick={closeModal} style={{ top: '0.25rem', right: '0.25rem' }}>&#10005;</button>   


      <PrivacyPolicy closeModal={closeModal}/>
      </div>
    </>)
  return ReactDOM.createPortal(
     modal, document.getElementById("modal-root")
  );
};

export default PrivacyPolicyModal;
